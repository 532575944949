<template>
  <div class="marketing-league-usage-record">
    <PageTitle :title="$route.meta.title" hideBtn />
    <FiltersContainer>
      <el-input
        v-model="search.phone"
        type="tel"
        clearable
        placeholder="輸入電話號碼"
        @change="handleSearch"
      />
    </FiltersContainer>
    <section>
      <UsageTable :data="usageRecord" />
      <Pagination
        :curPage.sync="search.page"
        :pageLimit="search.limit"
        :total="search.dataCount"
        @pageChange="handlePageChange"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UsageTable from './components/UsageTable'

const elLoadingOptions = {
  lock: true,
  target: '.marketing-league-usage-record',
}

export default {
  name: 'MarketingLeagueUsageRecord',
  components: { UsageTable },
  data () {
    return {
      search: {
        phone: '',
        page: 1,
        limit: 10,
        dataCount: 0,
      },
    }
  },
  computed: {
    ...mapGetters('marketing-league', {
      usageRecord: 'usageRecord',
    }),
  },
  mounted () {
    this.getUsageRecord()
  },
  methods: {
    async getUsageRecord () {
      const loading = this.$loading(elLoadingOptions)
      const { count } = await this.$store.dispatch('marketing-league/FIND_USAGE_RECORD', this.search)
      this.search.dataCount = count
      loading.close()
    },
    handlePageChange (page) {
      this.search.page = page
      this.getUsageRecord()
    },
    handleSearch () {
      this.search.page = 1
      this.getUsageRecord()
    },
  },
}
</script>

<style lang="scss">
.marketing-league-usage-record {
}
</style>
